// EditorMain画面のヘッダのメニューのアイテム

export default {
  main: {
    pc: [
      { code: 'stamp', icon: 'smile', text: 'スタンプ', iconColor: '#FF73A5' },
      { code: 'text', icon: 'font', text: '文字の追加', iconColor: '#E317CF' },
      { code: 'bg', icon: 'palette', text: '背景の色', iconColor: '#0D86DD' },
      { code: 'layout', icon: 'th-large', text: 'レイアウト', iconColor: '#6335C2' },
      { code: 'undo', icon: 'undo', text: '元に戻す', iconColor: '#0BBD72' },
      { code: 'save', icon: 'file-alt', text: '保存', iconColor: '#4DB80B' },
      { code: 'preview', icon: 'play-circle', text: 'プレビュー', iconColor: '#FE8653' }
    ],
    sm: [
      { code: 'layout', icon: 'th-large', text: 'レイアウト', iconColor: '#6335C2' },
      { code: 'bg', icon: 'palette', text: '背景の色', iconColor: '#0D86DD' },
      { code: 'save', icon: 'file-alt', text: '保存', iconColor: '#4DB80B' },
      { code: 'preview', icon: 'play-circle', text: 'プレビュー', iconColor: '#FE8653' }
    ]
  },
  preview: {
    pc: [
      { code: 'edit', icon: 'edit', text: '編集画面へ', iconColor: '#366def' }
    ],
    sm: [
      { code: 'edit', icon: 'edit', text: '編集画面へ', iconColor: '#366def' }
    ]
  }
}
