// 編集画面のヘッダメニューのアイテム

<template lang="pug">
button.header-menu-item.p-2.text-center(
  :disabled='isDisabled ? "disabled" : null'
  @click='$emit("click")'
)
  fa-icon.text-2xl(
    :icon='icon',
    :style='{ color: iconColor }'
  )
  p.text-xs.leading-tight(
    :style='{ color: textColor }'
  ) {{ text }}
</template>

<script>
export default {
  name: 'HeaderMenuItem',

  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: '#666'
    },
    textColor: {
      type: String,
      default: '#666'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.header-menu-item
  min-width: 4rem
  &:hover
    background: rgba(255,255,255,0.3)
  &:disabled
    cursor: not-allowed
    background: transparent
    > *
      color: #aaa !important
</style>
