// カラーパレット

export default {
  // 文字追加用のカラー
  text: [
    '#009040', // 緑
    '#0097da', // 水色
    '#1c1e84', // 紺
    '#e95098', // ピンク
    '#e60112', // 赤
    '#784421', // 茶
    '#ffcc00', // オレンジ
    '#ffffff', // 白
    '#000000' // 黒
  ],
  // スプレッドの背景色
  background: [
    // 白
    '#ffffff',
    // 黄色
    '#fffee9',
    '#fff6c5',
    '#ffe99d',
    // 緑系
    '#f1fceb',
    '#d2f2ce',
    '#c2f09d',
    // 緑系2
    '#cff0ea',
    '#b1d9d3',
    '#a3d0c9',
    // 青系
    '#dcf3fa',
    '#c9ecf8',
    '#b9e1f2',
    // 紫系
    '#f6f1fa',
    '#f1e9f8',
    '#e4ccf3',
    // ピンク系
    '#ffe6e9',
    '#ffd8dd',
    '#fbbdbd',
    // 茶色系
    '#fbefe9',
    '#fae6dd',
    '#e7c5b9',
    // 濃い色
    '#ff8822',
    '#00a381',
    '#192f60',
    '#824880',
    '#eb6ea5'
  ]
}
