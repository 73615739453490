// チュートリアル

<template lang="pug">
modal-base.tutorial-for-editor(
  size='m'
)
  .p-2(
    :style='{ "height": isSmartphone ? `${browserHeight}px` : "auto" }'
  )
    //- スマホのためにh-fullにする
    carousel.h-full(
      :per-page='1',
      :navigation-enabled='true',
      :navigation-click-target-size='12',
      pagination-position='bottom-overlay'
    )
      template(v-if='isSmartphone')
        slide
          img.mx-auto(src='../assets/sp-step-1.jpg')
        slide
          img.mx-auto(src='../assets/sp-step-2.jpg')
        slide
          img.mx-auto(src='../assets/sp-step-3.jpg')
        slide
          img.mx-auto(src='../assets/sp-step-4.jpg')
        slide
          img.mx-auto(src='../assets/sp-step-5.jpg')
        slide
          img.mx-auto(src='../assets/sp-step-6.jpg')

      template(v-else)
        slide
          img(src='../assets/pc-step-1.jpg')
        slide
          img(src='../assets/pc-step-2.jpg')
        slide
          img(src='../assets/pc-step-3.jpg')
        slide
          img(src='../assets/pc-step-4.jpg')
        slide
          img(src='../assets/pc-step-5.jpg')
        slide
          img(src='../assets/pc-step-6.jpg')

      slide.flex.items-center.justify-center
        .text-center
          p.text-xl.font-bold.text-gray-700 さあ、編集を始めましょう!
          p.mt-5
            button.btn.px-5.py-3.bg-blue-500.text-white(
              @click='$closeModal(true)'
            )
              fa-icon(icon='edit')
              span.ml-2 編集開始
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { mapGetters } from 'vuex'

import ModalBase from './modal-base'

export default {
  name: 'TutorialForEditor',

  components: {
    Carousel,
    Slide,
    ModalBase
  },

  computed: {
    ...mapGetters({
      isSmartphone: 'app/isSmartphone',
      browserHeight: 'app/browserHeight'
    })
  }
}
</script>

<style lang="sass">
.tutorial-for-editor
  // 次前ボタンを内部に表示
  .VueCarousel-navigation-button
    transform: none !important
</style>

<style lang="sass" scoped>
.tutorial-for-editor
  background: #fffdea
  +sm
    background: #fff
</style>
