// 編集画面用のヘッダメニュー

<template lang="pug">
ul.header-menu.flex.justify-between.items-center(
  :class='isPreview ? "bg-green-300" : "bg-blue-200"'
)
  li
    header-menu-item(
      icon='bars',
      text='メニュー',
      @click='clickMenuItem("menu")'
    )
  li
    ul.flex.items-center
      li(v-for='item in memuItems')
        header-menu-item(
          v-bind='item',
          @click='clickMenuItem(item.code)'
        )
  li
    header-menu-item(
      icon='shopping-cart',
      text='カゴに追加',
      icon-color='#ff9900',
      @click='clickMenuItem("cart")'
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import HeaderMenuItem from './header-menu-item'

import memuItems from '../../../utils/editor-header-menu-items'

export default {
  name: 'HeaderMenu',

  components: {
    HeaderMenuItem
  },

  props: {
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isLandscapeDevice: 'app/isLandscapeDevice',
      isSmartphone: 'app/isSmartphone',
      // コンテントを選択中かどうかの判定に利用
      selectedContentId: 'editor/selectedContentId',
      hasHistories: 'editor/hasHistories',
      currentSpreadIsBackgroundChangeable: 'editor/currentSpreadIsBackgroundChangeable',
      currentSpreadIsLayerTemplateChangeable: 'editor/currentSpreadIsLayerTemplateChangeable',
      viewPortInPreview: 'editor/viewPortInPreview'
    }),
    memuItems () {
      // disabledにするメニューのコード
      const disabled = []
      // アンドゥできないか
      if (!this.hasHistories) disabled.push('undo')
      // 背景変更不可か
      if (!this.currentSpreadIsBackgroundChangeable) disabled.push('bg')
      // レイヤテンプレート変更不可か
      if (!this.currentSpreadIsLayerTemplateChangeable) disabled.push('layout')
      // スマホかつ縦長ならスマホ用メニューを返す
      let items = this.isSmartphone && !this.isLandscapeDevice
        ? memuItems[this.isPreview ? 'preview' : 'main'].sm
        : memuItems[this.isPreview ? 'preview' : 'main'].pc
      if (this.isPreview && this.isSmartphone && !this.isLandscapeDevice) {
        items = [
          ...items,
          {
            code: 'viewport',
            icon: 'book-open',
            iconColor: '#25da1a',
            text: this.viewPortInPreview === 'both' ? 'ページ表示' : '見開き表示'
          }
        ]
      }
      return items.map(item => {
        return {
          ...item,
          disabled: disabled.indexOf(item.code) >= 0
        }
      })
    }
  },

  methods: {
    ...mapActions({
      unselectContent: 'editor/unselectContent'
    }),
    clickMenuItem (code) {
      // まず、選択中のコンテントがあればアンセレクト
      this.unselectContent()
      this.$emit('clickMenuItem', code)
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
